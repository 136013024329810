import { Container } from "react-bootstrap";

function PageNotFound() {
    return(
        <Container>
            <h1>Page not found :(</h1>
        </Container>
    );

}

export default PageNotFound;