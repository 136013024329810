import React from "react";

function Footer ()
{
  return (
    <body className="footerStyle">
      Ally Rilling || Computer Science and Finance || University of Wisconsin - Madison || anrilling@wisc.edu
    </body>
  );
}

export default Footer;