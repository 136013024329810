import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import '../CSScomponents/Nav.css';
import icon from '../images/mylogo.svg';

export default class Nav extends Component {
	render() {
		return (
			<div>
				<nav class='navbar navbar-expand-lg navbar-light bg-light'>
					<a class='navbar-brand' href='#'>
						Navbar
					</a>

					<button
						class='navbar-toggler'
						type='button'
						data-toggle='collapse'
						data-target='#navbarNav'
						aria-controls='navbarNav'
						aria-expanded='false'
						aria-label='Toggle navigation'
					>
						<span class='navbar-toggler-icon'></span>
					</button>

					<div class='collapse navbar-collapse' id='navbarNav'>
						<ul class='navbar-nav'>
							<li class='nav-item active'>
								<a class='nav-link' href='#'>
									Home <span class='sr-only'>(current)</span>
								</a>
							</li>

							<li class='nav-item'>
								<a class='nav-link' href='#'>
									Features
								</a>
							</li>

							<li class='nav-item'>
								<a class='nav-link' href='#'>
									Pricing
								</a>
							</li>
						</ul>
					</div>
				</nav>
			</div>
		);
	}
}
